.portfolio-wrapper {
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
  }
  
  .portfolio-wrapper img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .portfolio-wrapper .label {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-0%, -0%);
    background: rgba(0, 0, 0, 0.7); /* Slightly darker background for better readability */
    color: white;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
   
  }
  html {
    scroll-behavior: smooth;
  }
  
  
  .portfolio-wrapper {
    border: 0.5px solid black;
  }
  