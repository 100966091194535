.testimonials-block ul {
    list-style-type: none; /* Remove default list bullets */
    padding-left: 0;       /* Remove default padding */
  }
  
  .testimonials-block li {
    position: relative;   /* Position list items relative to enable custom bullet positioning */
    padding-left: 20px;   /* Space between bullet and text */
    margin-bottom: 10px;  /* Space between list items */
    text-align: left;     /* Align text to the left */
  }
  
  .testimonials-block li::before {
    content: '';                  /* No text content for the pseudo-element */
    position: absolute;           /* Absolute position to place bullet */
    left: 0;                      /* Position bullet on the left */
    top: 50%;                     /* Center bullet vertically */
    transform: translateY(-50%);  /* Adjust position to be vertically centered */
    width: 10px;                  /* Width of the bullet */
    height: 10px;                 /* Height of the bullet */
    background-color: #fff;       /* Bullet color (black in this case) */
    border-radius: 50%;           /* Make the bullet circular */
  }
  