/* AppAbout.css */

.block {
    padding: 40px 0;
    background-color: #f8f9fa;
  }
  
  .section-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  
  .text-section {
    padding: 20px;
  }
  
  .subsection-title {
    font-size: 1.5rem;
    font-weight: bolder;
    margin-top: 20px;
  }
  
  .video-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

  }
  
  .video-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(360px, 1fr)); /* Adjust the minimum width as needed */
    gap: 20px; /* Adjust the gap between videos */
    margin-left: 600px;
  }
  
  .video-player {
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    max-width: 100%;
    width: 100%; /* Adjust this width if necessary */
  }
  
  @media (max-width: 768px) {
    .video-grid {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); /* Adjust columns for smaller screens */
      gap: 10px; /* Adjust gap for smaller screens */
      margin-right: 600px;
    }
  
    .video-player {
      max-width: 100%; /* Full width on smaller screens */
    }
  }