.language-switcher {
    display: flex;
    margin-right: 45px;
  }
  
  .language-button {
    margin-right: 5px;
    padding: 5px 8px;
    border: none;
    background-color:#066F6A ;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .language-button:hover {
    background-color: #0fc2b9;
  }
  /* AppHeader.css */

.navbar-brand {
  font-size: 1.2rem; /* Adjust the font size as needed */
}

.nav-link {
  font-size: 1rem; /* Adjust the font size as needed */
}

.language-button {
  font-size: 0.9rem; /* Adjust the font size as needed */
}
.logo {
  height: 40px; /* Adjust the height as needed */
  margin-right: 10px; /* Space between the logo and the text */
}

  